<template>
  <b-row>
    <b-col
      :md="setPreviewCols(showPreview)"
      sm="12"
    >
      <b-card-group deck>
        <b-card :img-src="require('@/assets/images/schedule3.png')">
          <validation-observer
            ref="editRecurringEventForm"
            #default="{invalid}"
          >
            <!-- form -->
            <b-form class="mt-2">
              <b-card-body class="padding-top-bottom">
                <b-row v-if="formData">
                  <b-col sm="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                    >
                      <validation-provider
                        #default="{ errors }"
                        ref="name"
                        name="Event name"
                        vid="name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="formData.name"
                          placeholder="name"
                          name="name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      label="Day of Week"
                      label-for="day_of_week"
                    >
                      <b-form-select
                        v-model="formData.day_of_week"
                        :options="options"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label-cols-sm="4"
                      label-cols-lg="4"
                      content-cols-sm
                      content-cols-lg="8"
                      label="time"
                      label-for="time"
                    >
                      <b-input-group class="mb-1">
                        <b-form-timepicker
                          v-model="formData.time"
                          locale="en"
                          minutes-step="5"
                        />
                      </b-input-group>
                    <!--                  <b-form-input-->
                    <!--                    v-model="formData.time"-->
                    <!--                    placeholder="time"-->
                    <!--                    name="time"-->
                    <!--                  />-->
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer class="no-padding">
                <b-row>
                  <b-col cols="6" />
                  <b-col
                    cols="6"
                    class="col d-flex justify-content-end"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2"
                      :disabled="invalid || isSubmitting"
                      @click="submitForm"
                    >
                      Save changes
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card v-if="showPreview">
          <b-card-header class="padding-top-bottom">
            <h3>Event preview:</h3>
          </b-card-header>
          <div class="calendar-card">
            <div class="calendar-card-inner">
              <div class="calendar-card-name bg-gradient-primary">
                <h3>{{ formData.name }}</h3>
              </div>
              <div class="calendar-card-time">
                <h3>
                  {{ formData.time }}
                </h3>
              </div>
              <div class="calendar-card-days">
                <div
                  v-for="day in options"
                  :class="[selectActiveDay(day.value), 'calendar-card-day']"
                >
                  <div class="calendar-card-day-inner">
                    <div class="calendar-card-day-name">
                      <strong>{{ day.short }}</strong>
                      <small>{{ day.text }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-card-group>
    </b-col>
    <b-col cols="12 mt-50">
      <hr>
    </b-col>
  </b-row>

</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardGroup,
  BCardHeader,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormTimepicker,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import { cloneDeep } from 'lodash'
import { handleValidationError } from '@/utils/helpers'
import store from '@/store'

export default {
  components: {
    BCard,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormCheckbox,
    BFormTimepicker,
    BCardBody,
    BCardFooter,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BCardGroup,
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    initialFormData: Object,
    onFormSaved: Function,
  },
  data() {
    return {
      isSubmitting: false,
      formData: cloneDeep(this.initialFormData),
      showPreview: false,
      options: [
        {
          value: 0,
          text: 'Sunday',
          short: 'SUN',
        },
        {
          value: 1,
          text: 'Monday',
          short: 'MON',
        },
        {
          value: 2,
          text: 'Tuesday',
          short: 'TUE',
        },
        {
          value: 3,
          text: 'Wednesday',
          short: 'WED',
        },
        {
          value: 4,
          text: 'Thursday',
          short: 'THU',
        },
        {
          value: 5,
          text: 'Friday',
          short: 'FRI',
        },
        {
          value: 6,
          text: 'Saturday',
          short: 'SUN',
        },
      ],
      required,
    }
  },
  methods: {
    async submitForm() {
      this.$refs.editRecurringEventForm.validate()
        .then(async success => {
          if (success) {
            this.isSubmitting = true
            const data = {
              location_id: this.formData.location_id,
              name: this.formData.name,
              day_of_week: this.formData.day_of_week,
              // cut away seconds from time
              time: this.formData.time.split(':').slice(0, 2).join(':'),
            }

            let apiResponse
            try {
              if (this.formData.id) {
                apiResponse = await AccountService.updateRecurringEvent(this.formData.id, data)
              } else {
                apiResponse = await AccountService.createRecurringEvent(data)
              }
              store.dispatch('auth/setAuthUser', apiResponse.data.data)
              this.onFormSaved(apiResponse.data)
            } catch (error) {
              handleValidationError(error, this)
              console.error(error)
            } finally {
              this.isSubmitting = false
            }
          }
        })
    },
    resetForm() {
      this.formData = this.initialFormData
    },
    selectActiveDay(index) {
      return this.formData.day_of_week === index ? 'bg-gradient-primary' : ''
    },
    setPreviewCols() {
      return this.showPreview ? 8 : 6
    },
  },
}
</script>

<style scoped>
  .b-form-timepicker[variant="outline-primary"] {
    border-color: #7368ef !important;
    background-color: #FFF !important;
    border-right: 1px solid;
    color: #7368ef !important;
    /* padding: 0 12px; */
    border-left: 1px solid #7368ef !important;
    border: 1px solid #7368ef;
    border-radius: 0 6px 6px 0;
  }

  .b-form-timepicker[variant="outline-primary"] button.btn-secondary {
    background-color: transparent !important;
    color: #7368ef !important;
    padding: 0 12px;
    border: none;
  }

  [dir] .padding-top-only {
    padding: 1.5rem 0 !important;
  }

  [dir] .card-body.padding-top-only {
    padding: 1.5rem 0;
  }

  [dir] .card-deck.padding-bottom-normal {
    padding-bottom: 1.5rem;
  }

  [dir] .no-padding {
    padding: 0 !important;
  }

  [dir] .no-bottom-padding {
    padding-bottom: 0 !important;
  }

  [dir] .padding-top-bottom {
    padding: 1.5rem 0 !important;

  }

  .calendar-card-days {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }

  .calendar-card-day {
    border-radius: 7px;
  }

  .calendar-card-day-inner {
    padding: 10px;
  }

  .calendar-card-day-name {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 10px 0;
  }

  .calendar-card-time h3 {
    font-size: 100px;
    color: #7368ef;
  }

  .calendar-card-time {
    padding: 70px;
    text-align: center;
    border: 1px dashed #DADADA;
    margin-bottom: 20px;
  }

  .calendar-card-name {
    padding: 30px;
    text-align: center;
  }

  .calendar-card-name h3 {
    color: #FFF;
  }
</style>
